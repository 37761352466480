import { Configs } from "../CONFIGS";
import { Txt_En } from "../txt/en";

const HeaderHome = () => {

   return (
      <>
         <div className="header_wrap">
            <div className="header_top">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-12 col-md-4 col-sm-4 col-xs-12">
                        <center>
                           <div className="date fadeInDown wow" style={{ float: 'none', visibility: 'visible' }}>
                              <span id="serverTime">
                                 ...
                              </span>
                              <span className="bitCoin" style={{ color: '#fdb717', display: 'none' }}></span>
                           </div>
                           <div className="btcrate fadeInDown wow" style={{ visibility: 'visible', position: 'absolute' }}></div>
                        </center>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <nav id="mainNav" className="navbar navbar-default">
            <div className="container">
               <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                     <div className="logo fadeInDown wow" style={{ visibility: 'visible' }}>
                        <a href="/">
                           <img src="/styles/images/logs1.png" alt="" />
                        </a>
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-5 col-xs-12">
                     <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul className="nav navbar-nav fadeInDown wow" style={{ visibility: 'visible' }}>
                           <li><a href="/">{Txt_En.m1}</a></li>
                           <li><a href="/#about">{Txt_En.m2}</a></li>
                           <li><a href="/#ref">{Txt_En.m3}</a></li>
                           <li><a href="/#stat">{Txt_En.m4}</a></li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                     <div className="login_wrap fadeInDown wow" style={{ visibility: 'visible' }}>
                        <div>
                           <div className="brit__lang">
                              <a href={Configs.TG_URL as any} rel='noreferrer' target="_blank" style={{ color: 'white' }}>
                                 <div className="brit__lang-current">
                                    Telegram
                                 </div>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </nav>
      </>
   );
};

export default HeaderHome;