import { Configs } from "../CONFIGS";
import { ILang } from "../interfaces/ILang";

export const Txt_En: ILang = {
   m1: "HOME",
   m2: "ABOUT US",
   m3: "AFFILIATE PROGRAM",
   m4: "STATISTICS",
   m5: "IN",
   m6: "HOURS",
   m7: "START",
   m8: "Minimum",
   m9: "Maximum",
   m10: "PROFIT CALCULATOR",
   m11: "SECTIGO SSL",
   m12: "Secured by Sectigo SSL Certificate which guarantee the protection of your transactions.",
   m13: "AUTOMATIC PAYMENTS",
   m14: "Get your payment automatically! The minimum withdrawal amount is 1 USD.",
   m15: "PROTECTED SERVERS",
   m16: `${Configs.NAME_CAPI} LTD uses a very powerful dedicated server with the highest level of protection.`,
   m17: `WELCOME TO ${Configs.NAME_CAPI} LTD`,
   m18: "Crypto-currency mining is considered to be one of the most promising and most liquid areas for investment in the last years. The vast majority of Internet users do not have sufficient opportunities to purchase expensive computing equipment, and the profitability of small investments in this area of activity is very doubtful. Our company offers an alternative option consisting in the rental of computing capacities for the cloud mining of crypto-currencies. The low level of start-up investments is set, all adult investors are invited to cooperate, and each participant of the project is guaranteed transparency and stability of dividends.",
   m19: "AFFILIATE PROGRAM",
   m20: `Level 1 - ${Configs.REF_PERCENT}%`,
   m21: `${Configs.NAME_CAPI} LTD has a profitable affiliate program. Each participant can use the level 1 affiliate program without an active deposit and make money easily.`,
   m22: "Total Investors",
   m23: "Total Deposited",
   m24: "Days Online",
   m25: "Total Paid",
   m26: "Last Operations",
   m27: "Here you can check the latest deposits and payouts",
   m28: "RECENT DEPOSITS",
   m29: "LAST PAYOUTS",
   m30: "Time",
   m31: "Amount",
   m32: "English",

   m33: "LOGOUT",
   m34: "MY DASHBOARD",
   m35: "TOTAL DEPOSITS:",
   m36: "TOTAL PAYOUTS",
   m37: "REFERRAL PROFIT",
   m38: "WELCOME",
   m39: "REGISTRATION DATE",
   m40: "Waiting for your deposit in",
   m41: "YOUR UNIQUE DEPOSIT ADDRESS",
   m42: "Account",
   m43: "Make Deposit",
   m44: "My Deposits",
   m45: "My Payouts",
   m46: "My Referrals",
   m47: "DEPOSIT AMOUNT",
   m48: "OPENING DATE",
   m49: "STATUS",
   m50: "PROFIT",
   m51: "Finished",
   m52: "DATE",
   m53: "SUM",
   m54: "WALLET",
   m55: "STATUS",
   m56: "AFFILIATE LINK",
   m57: "Total Referrals",
   m58: "Your profit",
   m59: "Login",
   m60: "Invested",
   m61: "Profit",
   m62: "Country",
   m63: "Share with your friends:",
   m64: "MY ACCOUNT",
   m65: "LOG OUT",
   m66: "Enter amount",
   m67: "TOTAL PROFIT*:",
   m68: "Transaction ID",
   m69: "Address",
}