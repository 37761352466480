import RootLayout from './common/RootLayout';
import { Txt_En } from '../../txt/en';
import { useContext, useEffect } from 'react';
import { MyContext } from '../../App';
import { GetTime, SecondsToDMYHis } from '../../utils/Utils';
import { Configs } from '../../CONFIGS';
import axios from 'axios';
import { IResPays } from '../../interfaces/interfaces';
import toast from 'react-hot-toast';

const Payouts = () => {
   const { wallet, aPays, aPaysTime, setAPaysTime, setAPays } = useContext(MyContext);

   useEffect(() => {
      (async () => {
         try {
            const nowTime = GetTime();
            if (nowTime < aPaysTime)
               return;
            setAPaysTime(nowTime + Configs.TIME_CACHE);
            const res = (await axios.get<IResPays>(`${Configs.API_URL}/payments`, { headers: { wallet } })).data;
            if (res.success === false)
               return toast.error(res.message);
            setAPays(res.data.payments);
         }
         catch (e) {
            setAPays(null);
            const err = e as any;
            toast.error(err?.response?.data?.message || err?.message || 'error');
         }
      })();
   }, [wallet, setAPays, aPaysTime, setAPaysTime, aPays]);

   return (
      <RootLayout>
         <div className="member-container">
            <div className="member-right" style={{ overflow: 'scroll' }}>
               <table width="100%" border={0} cellPadding={2} cellSpacing={0}>
                  <thead>
                     <tr>
                        <td className="inheader">{Txt_En.m52}</td>
                        <td className="inheader">{Txt_En.m53}</td>
                        <td className="inheader">{Txt_En.m54}</td>
                        <td className="inheader">{Txt_En.m55}</td>
                     </tr>
                  </thead>
                  <tbody>
                     {
                        aPays?.map((item, index) => (
                           <tr key={index}>
                              <td>{SecondsToDMYHis(item.time)}</td>
                              <td>{item.amount.toFixed(8)} {item.account}</td>
                              <td>{wallet || '...'}</td>
                              <td>{item.hash ? 'Paid' : 'Processing'}</td>
                           </tr>
                        ))
                     }
                  </tbody>
               </table>
            </div>
         </div>
      </RootLayout>
   );
};

export default Payouts;