/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, ReactNode, useContext } from 'react';
import HeaderHome from '../../HeaderHome';
import FooterHome from '../../FooterHome';
import { Txt_En } from '../../../txt/en';
import { useNavigate } from 'react-router-dom';
import { SecsToDMY } from '../../../utils/Utils';
import { MyContext } from '../../../App';
import { Configs } from '../../../CONFIGS';

const RootLayout: FC<{ children: ReactNode }> = ({ children }) => {

   const navigate = useNavigate();

   const { aCab, wallet } = useContext(MyContext);

   return (
      <>
         <HeaderHome />
         <div className="member_wrap">
            <div className="big-stats">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-12 col-md12 col-sm-12 col-xs-12">
                        <div className="membersidebar">
                           <ul>
                              <li>
                                 <span className="one">&nbsp;</span><a style={{ cursor: 'pointer' }} onClick={() => navigate('/cabinet')}>{Txt_En.m42}</a>
                              </li>
                              <li>
                                 <span className="three">&nbsp;</span><a style={{ cursor: 'pointer' }} onClick={() => navigate('/create')}>{Txt_En.m43}</a>
                              </li>
                              <li>
                                 <span className="four">&nbsp;</span><a style={{ cursor: 'pointer' }} onClick={() => navigate('/deposits')}>{Txt_En.m44}</a>
                              </li>
                              <li>
                                 <span className="eight">&nbsp;</span><a style={{ cursor: 'pointer' }} onClick={() => navigate('/payment')}>{Txt_En.m45}</a>
                              </li>
                              <li>
                                 <span className="seven">&nbsp;</span><a style={{ cursor: 'pointer' }} onClick={() => navigate('/partners')}>{Txt_En.m46}</a>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="member_inside">
               <div className="mem_wrapper">
                  <div className="member_left" style={{ paddingTop: '30%' }}>
                     <div className="left">
                        <span><img src="../styles/images/iconusername.png" alt="" /></span>
                        <p>{Txt_En.m38}</p>
                        <h2 style={{ textOverflow: 'ellipsis', overflow: 'hidden', paddingLeft: '5px' }}>{wallet || '...'}</h2>
                     </div>
                     <div className="right">
                        <span><img src="../styles/images/iconregdate.png" alt="" /></span>
                        <p>{Txt_En.m39}</p>
                        <h2>{aCab === null ? '...' : SecsToDMY(aCab.joined)}</h2>
                     </div>
                     <div className="right">
                        <b>
                           Please subscribe to the telegram channel to find out about our new projects.
                           <a href={Configs.TG_URL as any} target="_blank" rel="noreferrer">
                              <img style={{ marginTop: '-5px', marginLeft: '10px' }} src="/tg.png" alt="" width="28px" height="28px" />
                           </a>
                        </b>
                     </div>
                  </div>
               </div>
               {children}
            </div>
         </div>
         <FooterHome />
      </>
   );
};

export default RootLayout;