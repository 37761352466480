/* eslint-disable jsx-a11y/anchor-is-valid */

import HeaderHome from './HeaderHome';
import FooterHome from './FooterHome';
import { Txt_En } from '../txt/en';
import { useContext, useEffect, useState } from 'react';
import { MyContext } from '../App';
import { Configs } from '../CONFIGS';
import { GetTime, SecondsToDMYHis, ValidateWallet } from '../utils/Utils';
import axios from 'axios';
import { IResAuth, IResHome } from '../interfaces/interfaces';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

const Home = () => {
   const { wallet, setWallet, setACab, setACabTime, aHome, setAHome, aHomeTime, setAHomeTime } = useContext(MyContext);
   const [registering, setRegistering] = useState(false);
   const navigate = useNavigate();

   const { refcode } = useParams();

   const [calcAmt, setCalcAmt] = useState<number>(0);

   const handleLogin = async () => {
      const wallet = (document.querySelector('input[name="wallet"]') as HTMLInputElement).value;

      const validatedWallet = ValidateWallet(wallet);
      if (validatedWallet === false)
         return toast.error('error format wallet');
      try {
         setRegistering(true);
         const res = (await axios.post<IResAuth>(`${Configs.API_URL}/auth`, { wallet: validatedWallet, refcode: refcode })).data;
         if (res.success === false)
            return toast.error(res.message);
         localStorage.setItem('wallet', validatedWallet);
         setWallet(validatedWallet);
         setACab(res.data.user);
         setACabTime(GetTime() + 5);
         navigate('/cabinet');
      }
      catch (e) {
         const err = e as any;
         toast.error(err?.response?.data?.message || err?.message || 'error');
      }
      finally {
         setRegistering(false);
      }
   }

   useEffect(() => {
      (async () => {
         const nowTime = GetTime();
         if (aHome !== null || nowTime < aHomeTime)
            return;
         try {
            setAHomeTime(nowTime + 5);
            const res = (await axios.post<IResHome>(`${Configs.API_URL}/home`)).data;
            if (res.success === false)
               return toast.error(res.message);
            setAHome(res.data);
         }
         catch (e) {
            setAHome(null);
            const err = e as any;
            toast.error(err?.response?.data?.message || err?.message || 'error');
         }
      })();
   }, [aHome, aHomeTime, setAHome, setAHomeTime]);


   return (
      <div className="wrapper">
         <header className="header">
            <HeaderHome />
         </header>
         <div className="banner_wrap">
            <div className="container">
               <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 col-offset-5">
                  <div className="banner_left"><br />

                     <h2>
                        + {Configs.PERCENT}% {Txt_En.m5} 24 {Txt_En.m6}
                     </h2>

                     {
                        wallet ?
                           <div className="banner_buttons fadeInUp wow" style={{ visibility: 'visible' }}>
                              <button className="registration" onClick={() => navigate(`/cabinet`)}><span>{Txt_En.m64}</span></button>
                              <button className="registration" onClick={() => navigate(`/logout`)}><span>{Txt_En.m65}</span></button>
                           </div>
                           :
                           <div id="formLogin"><br />
                              <input style={{ fontSize: '13px', outline: 'none' }} type="text" name="wallet" className="inpts" placeholder="BTC/LTC/ETH/DOGE/TRX address" />
                              <br /><br />
                              <input type="submit" disabled={registering} value={Txt_En.m7} className="sbmt" onClick={handleLogin} />
                           </div>
                     }
                     <br /><br />
                     <div className="plan_wrap">
                        <div className="plan_box bounceInDown wow" style={{ animationDelay: '0.3s', marginLeft: '25%', width: '50%', visibility: 'visible' }}>
                           <span className="icon">
                              <img src="styles/images/plan2.png" alt="" />
                           </span>
                           <h2>+{Configs.PERCENT}%</h2>
                           <p>in 24 hours</p>
                           <ul>
                              <li>{Txt_En.m8}<span>1 USD</span></li>
                              <li>{Txt_En.m9}<span>5000 USD</span></li>
                           </ul>
                        </div>
                     </div>
                     <br /><br />
                     <div className="banner_buttons fadeInUp wow" style={{ visibility: 'visible' }}>
                        <a href="#" data-toggle="modal" data-target="#myModal" className="profit_calc">
                           {Txt_En.m10}
                        </a>
                     </div>
                     <div className="modal fade" id="myModal" role="dialog">
                        <div className="modal-dialog modal-sm">
                           <div className="modal-content">
                              <div className="modal-body">
                                 <div className="ctn-call-bitcon">
                                    <button type="button" className="close" data-dismiss="modal">×</button>
                                    <div className="ctn-modal">
                                       <h4>{Txt_En.m66}</h4>
                                       <input type="number" name="amount" id="amount" className="deposit" value={calcAmt || ''} onChange={e => setCalcAmt(Number(e.target.value))} />
                                    </div>
                                    <div className="ctn-modal ctn-modal-body">
                                       <h4>{Txt_En.m67}</h4>
                                       <p>+{Configs.PERCENT}% {Txt_En.m5} 24 {Txt_En.m6}: <span id="">{calcAmt > 5000 ? 'NaN' : (calcAmt * 2).toFixed(2)}</span> USD</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="featured_wrap">
            <div className="container">
               <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <div className="featured_box one zoomIn wow" style={{ visibility: 'visible', animationName: 'none' }}>
                     <h4>{Txt_En.m11}</h4>
                     <p>{Txt_En.m12}</p>
                  </div>
               </div>
               <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <div className="featured_box two zoomIn wow" style={{ visibility: 'visible', animationName: 'none' }}>
                     <h4>{Txt_En.m13}</h4>
                     <p>{Txt_En.m14}</p>
                  </div>
               </div>
               <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <div className="featured_box three zoomIn wow" style={{ visibility: 'visible', animationName: 'none' }}>
                     <h4>{Txt_En.m15}</h4>
                     <p>{Txt_En.m16}</p>
                  </div>
               </div>
            </div>
         </div>
         <div className="welcome_wrap" id="about">
            <div className="container">
               <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                     <div className="welcome_left"><img src="styles/images/welcome_th.png" alt="" /></div>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                     <div className="welcome_right">
                        <h1 className="slideInUp wow" style={{ animationDelay: '0.2s', visibility: 'visible', animationName: 'none' }}>{Txt_En.m17}</h1>
                        <p className="slideInUp wow" style={{ animationDelay: '0.3s', visibility: 'visible', animationName: 'none' }}>{Txt_En.m18}</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="referral_wrap" id="ref">
            <div className="container">
               <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                     <div className="ref_box fadeInLeft wow" style={{ visibility: 'visible', animationName: 'none' }}>
                        <div className="left"><img src="styles/images/referral_icon.png" alt="" /></div>
                        <div className="right">
                           <h3>{Txt_En.m19}</h3>
                           <h4>{Txt_En.m20}</h4>
                           <p>{Txt_En.m21}</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                     <div className="ref_box fadeInRight wow" style={{ visibility: 'visible', animationName: 'none' }}>
                        <div className="left"><img src="styles/images/register_icon.png" alt="" /></div>
                        <div className="right">
                           <h3>uk registered company</h3>
                           <h4 className="reg">#12457193</h4>
                           <p className="reg">41 Chester Road, WC London, WC2 6HI, United Kingdom</p>
                           <br />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className="stat_wrap" id="stat">
            <div className="container">
               <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                     <div className="stat flipInX wow" style={{ visibility: 'visible', animationName: 'none' }}>
                        <div className="left"><img src="styles/images/total_member.jpg" alt="" /></div>
                        <div className="right">
                           <h3>{aHome === null ? '...' : aHome.statistics.users}</h3>
                           <p>{Txt_En.m22}</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                     <div className="stat flipInX wow" style={{ visibility: 'visible', animationName: 'none' }}>
                        <div className="left"><img src="styles/images/total_deposited.jpg" alt="" /></div>
                        <div className="right">
                           <h3 style={{ fontSize: '20px' }}>Ƀ {aHome === null ? '...' : aHome.statistics.deposits.toFixed(6)} </h3>
                           <p>{Txt_En.m23}</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                     <div className="stat flipInX wow" style={{ visibility: 'visible', animationName: 'none' }}>
                        <div className="left"><img src="styles/images/days_online.jpg" alt="" /></div>
                        <div className="right">
                           <h3>{((GetTime() - 1706609982) / 86400).toFixed(0)}</h3>
                           <p>{Txt_En.m24}</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                     <div className="stat flipInX wow" style={{ visibility: 'visible', animationName: 'none' }}>
                        <div className="left"><img src="styles/images/total_withdrawals.jpg" alt="" /></div>
                        <div className="right">
                           <h3 style={{ fontSize: '20px' }}>Ƀ {aHome === null ? '...' : aHome.statistics.payments.toFixed(6)}</h3>
                           <p>{Txt_En.m25}</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>


         <div className="referral_wrap" id="stat">
            <div className="container">

               <h4 className="card-title">{Txt_En.m26}</h4>
               <h6 className="card-subtitle">{Txt_En.m27}</h6><br />

               <div className="tab-content">
                  <div className="tab-pane p-20 active" id="tabRecentDeposits" role="tabpanel">
                     <div className="table-responsive">
                        <table className="table table-striped">

                           <thead>
                              <tr>
                                 <td className="inheader"><b>{Txt_En.m30}</b></td>
                                 <td className="inheader"><b>{Txt_En.m68}</b></td>
                                 <td className="inheader"><b>{Txt_En.m31}</b></td>
                                 <td className="inheader"><b>Type</b></td>
                              </tr>
                           </thead>
                           <tbody>
                              {
                                 aHome?.transactions.map((r, i) => (
                                    <tr key={i}>
                                       <td>{SecondsToDMYHis(r.time)}</td>
                                       <td>
                                          <img src="assets/currencies/logos/$imgUrl.png" width="17" data-toggle="tooltip" data-placement="left" alt="" title="" data-original-title="" />
                                          &nbsp;&nbsp;&nbsp;
                                          <a href="#">
                                             {r.hash.slice(0, 25)}...
                                          </a>
                                       </td>
                                       <td>{r.amount.toFixed(8)} {r.account}</td>
                                       <td>{r.causale === 'Payment' ? r.causale : 'Deposit'}</td>
                                    </tr>
                                 ))
                              }
                           </tbody>
                        </table>
                     </div>
                  </div>

               </div>
            </div>
         </div>

         <FooterHome />
      </div>
   );
};

export default Home;