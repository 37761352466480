import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { IServerResponse } from "../interfaces/ServerResponse";
import { SerializedError } from "@reduxjs/toolkit";

export const sleep = (ms: number) => new Promise(r => setTimeout(() => r(''), ms));
export const TextEllipse = (text: string, maxLength: number): string => text.length < maxLength ? text : text.substring(0, maxLength) + '...';
export const randInt = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

export const getErrors = (r: IServerResponse | FetchBaseQueryError | SerializedError): string => {
   r = r as IServerResponse;
   if (r?.data?.text && r?.data?.errors) {
      const data = r.data as IServerResponse;
      let mErrors = `myError$$$${data.text}$$$`;
      data.errors.map((e, i) => mErrors += `${i === 0 ? `` : `::`}${e.path === 'any' ? e.message : `${e.path} - ${e.message}`}`);
      return mErrors;
   }
   if (r?.data?.code && r?.data?.text)
      return r.data.text as string;

   r = (r as any) as SerializedError;
   if (r?.message && r?.name)
      return `myError$$$${r.name}$$$${r.message}`;

   const x = (r as any);
   if (x.error && x.status)
      return `myError$$$${x.status as string}$$$${x.error as string}`;


   if (x?.text && x?.errors) {
      const data = x as IServerResponse;
      let mErrors = `myError$$$${data.text}$$$`;
      data.errors.map((e, i) => mErrors += `${i === 0 ? `` : `::`}${e.path === 'any' ? e.message : `${e.path} - ${e.message}`}`);
      return mErrors;
   }

   return `Unknown error`;
}


export const convertDate = (inputDate: string): string => {
   const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
   ];

   const date = new Date(inputDate);
   const day = date.getUTCDate();
   const month = months[date.getUTCMonth()];
   const year = date.getUTCFullYear().toString().slice(-2);
   const hours = ("0" + date.getUTCHours().toString()).slice(-2);
   const minutes = ("0" + date.getUTCMinutes().toString()).slice(-2);

   return `${day} ${month}, ${year} ${hours}:${minutes}`;
}

export const ValidateWallet = (wallet: string | null): false | string => {
   if (wallet === null)
      return false;
   wallet = wallet.trim();
   if (wallet.length < 30 || wallet.length > 40)
      return false;
   const walletf = wallet[0];
   if (["1", "3", "b", "M", "L", "A", "D", "9", "0", "T"].includes(walletf) === true)
      return wallet;
   return false;
}

export const GetTime = (): number => (Date.now() / 1000) | 0;

export const GetLocalWallet = () => {
   const wallet = localStorage.getItem('wallet');
   if (wallet === null) {
      window.location.href = '/';
      return null;
   }
   const valid = ValidateWallet(wallet);
   if (valid === false) {
      window.location.href = '/';
      return null;
   }
   return wallet;
}

export function SecsToDMY(timestampInSeconds: number) {
   // Create a Date object using the timestamp in milliseconds
   const date = new Date(timestampInSeconds * 1000);

   // Extract day, month, and year
   const day = date.getUTCDate();
   const month = date.getUTCMonth() + 1; // Month is zero-based, so we add 1
   const year = date.getUTCFullYear();

   // Format the components with leading zeros if needed
   const formattedDay = day < 10 ? '0' + day : day;
   const formattedMonth = month < 10 ? '0' + month : month;

   // Return the formatted date string in "D-M-Y" format
   return formattedDay + '-' + formattedMonth + '-' + year;
}

export function SecondsToDMYHis(timestampInSeconds: number) {
   // Create a Date object using the timestamp in milliseconds
   const date = new Date(timestampInSeconds * 1000);

   // Extract day, month, year, hours, minutes, and seconds
   const day = date.getUTCDate();
   const month = date.getUTCMonth() + 1; // Month is zero-based, so we add 1
   const year = date.getUTCFullYear();
   const hours = date.getUTCHours();
   const minutes = date.getUTCMinutes();
   const seconds = date.getUTCSeconds();

   // Format the components with leading zeros if needed
   const formattedDay = day < 10 ? '0' + day : day;
   const formattedMonth = month < 10 ? '0' + month : month;
   const formattedHours = hours < 10 ? '0' + hours : hours;
   const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
   const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;

   // Return the formatted date string in "d-m-Y H:i:s" format
   return (
      formattedDay +
      '-' +
      formattedMonth +
      '-' +
      year +
      ' ' +
      formattedHours +
      ':' +
      formattedMinutes +
      ':' +
      formattedSeconds
   );
}