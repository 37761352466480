import { JSX } from "react";
import { Navigate } from "react-router-dom";
import { GetLocalWallet } from "./Utils";

export const RequireAuth = ({ values: { wallet, loading }, children }: { values: { wallet: string, loading: boolean }, children: JSX.Element }): JSX.Element => {
   if (loading) return <></>;
   const walletd = GetLocalWallet();
   if (walletd === null) return <Navigate to='/' replace />;
   return children;
}
