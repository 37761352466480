import { Dispatch, SetStateAction, createContext, useCallback, useEffect, useState } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Cabinet from './components/dash/Cabinet';
import Deposits from './components/dash/Deposits';
import Payouts from './components/dash/Payouts';
import Partners from './components/dash/Partners';
import CreateDeposit from './components/dash/CreateDeposit';
import { GetTime, ValidateWallet } from './utils/Utils';
import toast, { Toaster } from 'react-hot-toast';
import { IHome, IInvest, IPayment, IReferral, IResAuth, IUser } from './interfaces/interfaces';
import { RequireAuth } from './utils/RequireUser';
import { Logout } from './components/dash/Logout';
import { Configs } from './CONFIGS';
import axios from 'axios';

interface IMyContext {
   wallet: string;
   setWallet: Dispatch<SetStateAction<string>>;
   aHome: IHome | null;
   setAHome: Dispatch<SetStateAction<IHome | null>>;
   aHomeTime: number;
   setAHomeTime: Dispatch<SetStateAction<number>>;
   aCab: IUser | null;
   setACab: Dispatch<SetStateAction<IUser | null>>;
   aCabTime: number;
   setACabTime: Dispatch<SetStateAction<number>>;
   aDeps: IInvest[] | null;
   setADeps: Dispatch<SetStateAction<IInvest[] | null>>;
   aDepsTime: number;
   setADepsTime: Dispatch<SetStateAction<number>>;
   aPays: IPayment[] | null;
   setAPays: Dispatch<SetStateAction<IPayment[] | null>>;
   aPaysTime: number;
   setAPaysTime: Dispatch<SetStateAction<number>>;
   aRefs: IReferral[] | null;
   setARefs: Dispatch<SetStateAction<IReferral[] | null>>;
   aRefsTime: number;
   setARefsTime: Dispatch<SetStateAction<number>>;
   LoadUser: () => void;
   loading: boolean;
}

export const MyContext = createContext<IMyContext>({
   wallet: '',
   setWallet: () => { },
   aHome: null,
   setAHome: () => { },
   aHomeTime: 0,
   setAHomeTime: () => { },
   aCab: null,
   setACab: () => { },
   aCabTime: 0,
   setACabTime: () => { },
   aDeps: null,
   setADeps: () => { },
   aDepsTime: 0,
   setADepsTime: () => { },
   aPays: null,
   setAPays: () => { },
   aPaysTime: 0,
   setAPaysTime: () => { },
   aRefs: null,
   setARefs: () => { },
   aRefsTime: 0,
   setARefsTime: () => { },
   LoadUser: () => { },
   loading: true,
});


function App() {
   const [wallet, setWallet] = useState<string>('');
   const [loading, setLoading] = useState<boolean>(true);

   useEffect(() => {
      setLoading(true);
      const wallet = localStorage.getItem('wallet');
      const validatedWallet = ValidateWallet(wallet);
      if (validatedWallet === false) {
         setLoading(false);
         return setWallet('');
      }
      setLoading(false);
      return setWallet(validatedWallet);
   }, []);

   /* start API RESPONSES */
   const [aHome, setAHome] = useState<IHome | null>(null);
   const [aHomeTime, setAHomeTime] = useState<number>(0);
   const [aCab, setACab] = useState<IUser | null>(null);
   const [aCabTime, setACabTime] = useState<number>(0);
   const [aDeps, setADeps] = useState<IInvest[] | null>(null);
   const [aDepsTime, setADepsTime] = useState<number>(0);
   const [aPays, setAPays] = useState<IPayment[] | null>(null);
   const [aPaysTime, setAPaysTime] = useState<number>(0);
   const [aRefs, setARefs] = useState<IReferral[] | null>(null);
   const [aRefsTime, setARefsTime] = useState<number>(0);
   /* end API RESPONSES */

   const LoadUser = useCallback(async () => {
      try {
         if (loading)
            return;
         const nowTime = GetTime();
         if (nowTime < aCabTime || aCab !== null || wallet === '')
            return;
         setACabTime(nowTime + Configs.TIME_CACHE);
         const res = (await axios.post<IResAuth>(`${Configs.API_URL}/auth`, { wallet })).data;
         if (res.success === false)
            return toast.error(res.message);
         setACab(res.data.user);
      }
      catch (e) {
         setACab(null);
         const err = e as any;
         toast.error(err?.response?.data?.message || err?.message || 'error');
      }
   }, [aCab, aCabTime, loading, wallet])

   useEffect(() => {
      LoadUser();
   }, [LoadUser]);

   return (
      <MyContext.Provider value={{
         loading, wallet, setWallet, LoadUser,
         aHome, setAHome, aHomeTime, setAHomeTime,
         aCab, setACab, aCabTime, setACabTime,
         aDeps, setADeps, aDepsTime, setADepsTime,
         aPays, setAPays, aPaysTime, setAPaysTime,
         aRefs, setARefs, aRefsTime, setARefsTime,
      }}>
         <Toaster
            toastOptions={{
               duration: 3000,
            }}
         />
         <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/logout" element={<Logout />} />


            <Route path="/cabinet" element={<RequireAuth values={{ wallet, loading }}><Cabinet /></RequireAuth>} />
            <Route path="/deposits" element={<RequireAuth values={{ wallet, loading }}><Deposits /></RequireAuth>} />
            <Route path="/payment" element={<RequireAuth values={{ wallet, loading }}><Payouts /></RequireAuth>} />
            <Route path="/partners" element={<RequireAuth values={{ wallet, loading }}><Partners /></RequireAuth>} />
            <Route path="/create" element={<RequireAuth values={{ wallet, loading }}><CreateDeposit /></RequireAuth>} />

            <Route path="/:refcode" element={<Home />} />

         </Routes>
      </MyContext.Provider>
   );
}

export default App;
