import { useContext, useEffect } from 'react';
import { Txt_En } from '../../txt/en';
import RootLayout from './common/RootLayout';
import { MyContext } from '../../App';
import { GetTime, SecondsToDMYHis } from '../../utils/Utils';
import { Configs } from '../../CONFIGS';
import axios from 'axios';
import { IResInvs } from '../../interfaces/interfaces';
import toast from 'react-hot-toast';

const Deposits = () => {
   const { wallet, aDeps, aDepsTime, setADepsTime, setADeps } = useContext(MyContext);

   useEffect(() => {
      (async () => {
         try {
            const nowTime = GetTime();
            if (nowTime < aDepsTime)
               return;
            setADepsTime(nowTime + Configs.TIME_CACHE);
            const res = (await axios.get<IResInvs>(`${Configs.API_URL}/deposits`, { headers: { wallet } })).data;
            if (res.success === false)
               return toast.error(res.message);
            setADeps(res.data.deposits);
         }
         catch (e) {
            setADeps(null);
            const err = e as any;
            toast.error(err?.response?.data?.message || err?.message || 'error');
         }
      })();
   }, [wallet, setADeps, aDepsTime, setADepsTime, aDeps]);

   return (
      <RootLayout>
         <div className="member-container">
            <div className="member-right" style={{ overflow: 'scroll' }}>
               <table width="100%" border={0} cellPadding={2} cellSpacing={0}>
                  <thead>
                     <tr>
                        <td className="inheader">{Txt_En.m47}</td>
                        <td className="inheader">{Txt_En.m48}</td>
                        <td className="inheader">{Txt_En.m49}</td>
                        <td className="inheader">{Txt_En.m50}</td>
                     </tr>
                  </thead>
                  <tbody>
                     {
                        aDeps?.map((dep, i) => (
                           <tr key={i}>
                              <td>{dep.amount.toFixed(8)} TRX</td>
                              <td>{SecondsToDMYHis(dep.started)}</td>
                              <td className="countdown">{(dep.started + 86400) < GetTime() ? 'Finished' : 'Active'}</td>
                              <td>{(dep.amount * dep.percent).toFixed(8)} {dep.account}</td>
                           </tr>
                        ))
                     }
                  </tbody>
               </table>
            </div>
         </div>
      </RootLayout>
   );
};

export default Deposits;