import React, { useContext, useEffect } from 'react';
import RootLayout from './common/RootLayout';
import { Txt_En } from '../../txt/en';
import { MyContext } from '../../App';
import { GetTime, SecondsToDMYHis } from '../../utils/Utils';
import { Configs } from '../../CONFIGS';
import axios from 'axios';
import { IResRefs } from '../../interfaces/interfaces';
import toast from 'react-hot-toast';

const Partners = () => {
   const { aCab, wallet, aRefs, aRefsTime, setARefsTime, setARefs } = useContext(MyContext);

   useEffect(() => {
      (async () => {
         try {
            const nowTime = GetTime();
            if (nowTime < aRefsTime)
               return;
            setARefsTime(nowTime + Configs.TIME_CACHE);
            const res = (await axios.get<IResRefs>(`${Configs.API_URL}/referrals`, { headers: { wallet } })).data;
            if (res.success === false)
               return toast.error(res.message);
            setARefs(res.data.referrals);
         }
         catch (e) {
            setARefs(null);
            const err = e as any;
            toast.error(err?.response?.data?.message || err?.message || 'error');
         }
      })();
   }, [wallet, setARefs, aRefsTime, setARefsTime, aRefs]);
   return (
      <RootLayout>
         <div className="member-container">
            <div className="member-right" style={{ overflow: 'scroll' }}>
               <script src="/js/clipboard.min.js"></script>
               <div className="my_accont"><br />
                  <div className="top" style={{ marginBottom: '15px' }}>
                     <p>{Txt_En.m56}</p>
                     <h2 id="link" style={{ float: 'left', width: '55%', textTransform: 'unset' }}>
                        https://{Configs.host}/{aCab?.refcode}
                     </h2>
                     <span className="copy" style={{ float: 'left', paddingTop: '5px' }}>
                        <input type="button" id="copy" value="Copy" data-clipboard-target="#link" />
                     </span>
                  </div>
                  <br /><br />
                  <table width="100%" cellSpacing={4} cellPadding={4}>
                     <tbody>
                        <tr>
                           <td width="50%" className="bluetext"><strong>{Txt_En.m57}</strong></td>
                           <td width="50%" className="item">
                              {aCab === null ? '...' : aCab.ref_count}
                           </td>
                        </tr>

                        <tr>
                           <td className="bluetext"><strong>{Txt_En.m58}</strong></td>
                           <td className="item">
                              {aCab === null ? '...' : aCab.ref_amount.toFixed(8)} {aCab?.account}
                           </td>
                        </tr>
                     </tbody>
                  </table>
                  <br />
                  <center>
                     <b style={{ fontSize: '24px' }}>
                        {Txt_En.m63}
                        <a href={`https://telegram.me/share/url?url=https://${Configs.host}/${aCab?.refcode}&text=${Configs.host}`} target="_blank" rel="noreferrer">
                           <img style={{ marginLeft: '10px' }} src="/tg.png" alt="" width="25px" height="25px" />
                        </a>
                     </b>
                  </center>
                  <br />
                  <table width="100%" id="ref-dts">
                     <thead>
                        <tr>
                           <td>Date</td>
                           <td>{Txt_En.m59}</td>
                           <td>{Txt_En.m60}</td>
                           <td>{Txt_En.m61}</td>
                        </tr>
                     </thead>
                     <tbody>
                        {
                           aRefs?.map((r, i) => (
                              <tr key={i}>
                                 <td>{SecondsToDMYHis(r.joined)}</td>
                                 <td>{r.wallet}</td>
                                 <td>{r.invest.toFixed(8)} {r.account}</td>
                                 <td>{r.bonus.toFixed(8)} {r.account}</td>
                              </tr>
                           ))
                        }
                     </tbody>
                  </table>
               </div>
               <br />
            </div>
         </div>
      </RootLayout>
   );
};

export default Partners;