import { useContext, useEffect } from 'react';
import { Txt_En } from '../../txt/en';
import RootLayout from './common/RootLayout';
import { MyContext } from '../../App';

const Cabinet = () => {
   const { aCab, LoadUser } = useContext(MyContext);

   useEffect(() => {
      LoadUser();
   }, [LoadUser]);

   return (
      <RootLayout>

         <div className="member-container">
            <div className="member-right" style={{ overflow: 'scroll' }}>
               <div className="detailed-stats-box">
                  <br />
               </div>
               <style>

               </style><br />
               <div className="top">
                  <p>Affiliate Link</p>
                  <h2 id="link" style={{ float: 'left', width: '55%' }}>http://{window.location.host}/{aCab === null ? '...' : aCab.refcode}</h2>
                  <span className="copy" style={{ float: 'left', paddingTop: '5px' }}>
                     <input type="button" id="copy" value="Copy" data-clipboard-target="#link" />
                  </span>
               </div>
               <div className="account_mid">
                  <div className="mem_mid one">
                     <div className="mem_mid_left">
                        <span className="acc-bal">&nbsp;</span>
                     </div>
                     <div className="mem_mid_right">
                        <strong>{Txt_En.m35}</strong>
                        <p className="big">{aCab === null ? '...' : aCab.dep_amount.toFixed(8) + ' ' + aCab.account}</p>
                     </div>
                  </div>
                  <div className="mem_mid two">
                     <div className="mem_mid_left">
                        <span className="ear-tot">&nbsp;</span>
                     </div>
                     <div className="mem_mid_right">
                        <strong>{Txt_En.m36}</strong>
                        <p className="big">{aCab === null ? '...' : aCab.pay_amount.toFixed(8) + ' ' + aCab.account}</p>
                     </div>
                  </div>
                  <div className="mem_mid three">
                     <div className="mem_mid_left">
                        <span className="acc-dep">&nbsp;</span>
                     </div>
                     <div className="mem_mid_right">
                        <strong>{Txt_En.m37}</strong>
                        <p className="big">{aCab === null ? '...' : aCab.ref_amount.toFixed(8) + ' ' + aCab.account}</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </RootLayout >
   );
};

export default Cabinet;