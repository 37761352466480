export enum Configs {
   API_URL = 'https://api.crypto-first.ltd/api/v1',
   PERCENT = 100,
   PERC = 1,
   NAME = 'Crypto-First',
   NAME_LOW = 'crypto-first',
   NAME_CAPI = 'CRYPTO-FIRST',
   host = 'crypto-first.ltd',
   email = 'support@crypto-first.ltd',
   TIME_CACHE = 5,
   TG_URL = 'https://t.me/paradise_official_s',
   REF_PERCENT = 20,
}