import { Configs } from "../CONFIGS";

const FooterHome = () => {
   return (
      <footer>
         <div className="container">
            <div className="row">
               <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                  <div className="fadeInUp wow">2024 © {Configs.NAME_CAPI} LTD <br />All Rights Reserved.</div>
               </div>
               <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12"></div>
               <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
                  <div className="footer_links fadeInUp wow">
                     <a href="/">Home</a>
                     <a href="/#about">About Us</a>
                     <a href="/?page=rules">Rules</a>
                     <br />
                     <span>{Configs.email}</span>
                  </div>
               </div>
            </div>
         </div>
      </footer>
   );
};

export default FooterHome;