import { useContext, useEffect, useState } from "react";
import RootLayout from "./common/RootLayout";
import axios from "axios";
import { Configs } from "../../CONFIGS";
import { MyContext } from "../../App";
import { EAccounts, ICreate, IResCreate } from "../../interfaces/interfaces";
import toast from "react-hot-toast";
import { Txt_En } from "../../txt/en";

const CreateDeposit = () => {

   const { loading, wallet, aCab } = useContext(MyContext);

   const [data, setData] = useState<ICreate | null>(null);
   const [depAccount, setDepAccount] = useState<EAccounts | null>(null);

   useEffect(() => {
      if (loading || aCab === null)
         return;
      setDepAccount(aCab.account);
   }, [aCab, loading]);

   useEffect(() => {
      (async () => {
         if (loading || depAccount === null)
            return;
         try {
            setData(null);
            const res = (await axios.get<IResCreate>(`${Configs.API_URL}/create?account=${depAccount}`, { headers: { wallet } })).data;
            if (res.success === false)
               return toast.error(res.message || 'unknown error');
            setData(res.data.create);
         }
         catch (e) {
            setData(null);
            const err = e as any;
            toast.error(err?.response?.data?.message || err?.message || 'error');
         }
      })();
   }, [depAccount, loading, wallet]);

   return (
      <RootLayout>
         <div className="member-container">
            <div className="member-right" style={{ overflow: 'scroll' }}>
               <table cellSpacing="0" cellPadding="2" className="form deposit_confirm" width="100%" align="center">
                  <tbody>
                     <tr>
                        <td rowSpan={6} align="center">
                           {
                              data?.wallet && <img src={`https://chart.googleapis.com/chart?cht=qr&chs=244x244&chl=${data?.wallet}&chld=H|0`} alt="" />
                           }
                        </td>
                        <td align="center">
                           {Txt_En.m40}&nbsp;
                           <span>{data?.account || '...'}</span>
                        </td>
                     </tr>
                     <tr>
                        <td align="center"><b>{Txt_En.m41}</b></td>
                     </tr>
                     <tr>
                        <td>
                           <div className="alert alert-success">
                              <h4 id="copy" style={{ cursor: 'pointer', margin: 0 }} data-clipboard-text={data?.wallet}>
                                 {data?.wallet || '...'}
                              </h4>
                           </div>
                        </td>
                     </tr>
                     <tr>
                        <td align="center">
                           <div className="alert alert-info">
                              To complete a deposit in&nbsp;
                              <span className="deposit-currency-symbol font-bold">
                                 {data?.account || '...'},&nbsp;
                              </span>
                              you need&nbsp;
                              <span className="deposit-currency-symbol font-bold">
                                 {data?.confirms || '...'}&nbsp;
                              </span>
                              confirmations. Minimum deposit amount&nbsp;
                              <span className="deposit-currency-symbol font-bold">
                                 {data?.min || '...'}&nbsp;{data?.account || '...'}
                              </span>
                           </div>
                        </td>
                     </tr>

                     <tr>
                        <center style={{ margin: '20px 0' }}>
                           Select Currency: &nbsp;
                           <select onChange={e => setDepAccount(e.target.value as EAccounts)} style={{ outline: 'none', cursor: 'pointer' }} value={depAccount || ""}>
                              <option value="BTC">BTC</option>
                              <option value="LTC">LTC</option>
                              <option value="DOGE">DOGE</option>
                              <option value="ETH">ETH</option>
                              <option value="TRX">TRX</option>
                           </select>
                        </center>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
      </RootLayout>
   );
};

export default CreateDeposit;